import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import ProjectPocketDetails from './projectPocketDetails';
export default {
  name: 'ProjectPocket',
  watch: {
    currentPage: function() {
      this.getProjectPocketList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectPocketList();
    }
  },
  components: {
    ProjectPocketDetails
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      showModalComponent: false,
      showValueSetModal: false,
      loader: false,
      editMode: false,
      payload: {},
      showAlert: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocketCode: null,
      pocketName: null,
      tab: null,
      parentProjectPocket: null,
      projectPocketData: [],
      projectPocketFields: [
        {
          key: 'master_project_id',
          class: 'd-none'
        },
        {
          key: 'master_project_name'
        },
        {
          key: 'sector_id',
          class: 'd-none'
        },
        {
          key: 'sector_name'
        },
        {
          key: 'pocket_code'
        },
        {
          key: 'pocket_name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'sequence'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'ProjectPocketDetails');
          this.parentProjectPocket = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'projects/getProjectPocketList',
            'project-pocket',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getProjectPocketList();
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ProjectPocketDetails') {
        return (this.childName = 'Project Pocket Details');
      }
    },
    getProjectPocketList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        master_project_id: this.masterPrj.value,
        sector_id: this.sector.value,
        pocket_code: this.pocketCode,
        pocket_name: this.pocketName
      };
      this.loader = true;
      this.$store
        .dispatch('projects/getProjectPocketList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status == 200) {
            this.projectPocketData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideModalComponent(flag, component) {
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.parentProjectPocket = rowData;
      this.hideModalComponent(true, 'ProjectPocketDetails');
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.sector = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.parent_value_set_id = this.masterPrj.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    updateProjectPocketList() {
      this.getProjectPocketList();
    },
    resetSearchFilters() {
      this.masterPrj = {
        value: null,
        text: null
      };
      this.sector = {
        value: null,
        text: null
      };
      this.parent_value_set_id = null;
      this.pocketCode = null;
      this.pocketName = null;

      this.getProjectPocketList();
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: null,
          text: null
        };
        this.sector = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
