import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ProjectPocketDetails',
  props: {
    parentProjectPocket: Object
  },
  components: {
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocketCode: null,
      pocketName: null,
      pocketId: 0,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      version: null,
      approvalStatus: null,
      sequence: null
    };
  },
  mounted() {
    if (this.parentProjectPocket) {
      this.fillDataFromParent();
  } else { this.editMode = true }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjectPocket();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.pocketId });
        }
      }
    });
  },
  methods: {
    fillDataFromParent(){
      this.masterPrj = {
        value: this.parentProjectPocket.master_project_id,
        text: this.parentProjectPocket.master_project_name
      };
      this.sector = {
        value: this.parentProjectPocket.sector_id,
        text: this.parentProjectPocket.sector_name
      };
      this.pocketCode = this.parentProjectPocket.pocket_code;
      this.pocketId = this.parentProjectPocket.pocket_id;
      this.pocketName = this.parentProjectPocket.pocket_name;
      this.startDate = this.parentProjectPocket.start_date;
      this.endDate = this.parentProjectPocket.end_date;
      this.version = this.parentProjectPocket.version;
      this.approvalStatus = this.parentProjectPocket.approval_status;
      this.sequence = this.parentProjectPocket.sequence;
    },
    addEditProjectPocket() {
      const payload = {
        sector_id: this.sector.value,
        pocket_code: this.pocketCode,
        pocket_id: this.pocketId,
        pocket_name: this.pocketName,
        sequence: this.sequence,
        start_date:  this.startDate,
        end_date: this.endDate
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectPocket', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateProjectPocketList');
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.startDate
      );
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.sector = {
          value: null,
          text: null
        };
      } else {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.parent_value_set_id = this.masterPrj.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: null,
          text: null
        };
        this.sector = {
          value: null,
          text: null
        };
      } else {
        this.sector = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
